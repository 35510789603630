import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../service/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import * as AOS from 'aos';
import Swal from 'sweetalert2';
declare var $: any;



@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  model = { APP_NAME: null, TENANT_ID: null, CLIENT_ID: null, CLIENT_SECRET: null, URL_SLUG: null}
  // product: string;
  resolveAPIResp: any;
  submitToggle: boolean = true
  productList: any

  constructor(private http: HttpClient, private authService: AuthService, private spinner: NgxSpinnerService,private route: ActivatedRoute ) { }

   ngOnInit() {
    AOS.init();
    // this.product = window.location.pathname.substring(1)
    this.onLoadProductList()

  }

  editProductListByURLSlug(urlSlug: any) {
    this.submitToggle = false
    this.spinner.show()
    this.authService.getProductListByURLSlug(urlSlug).subscribe(resp=>{
      this.model.APP_NAME = resp['primary'][0]['APP_NAME']
      this.model.CLIENT_ID = resp['primary'][0]['CLIENT_ID']
      this.model.CLIENT_SECRET = resp['primary'][0]['CLIENT_SECRET']
      this.model.TENANT_ID = resp['primary'][0]['TENANT_ID']
      this.model.URL_SLUG = resp['primary'][0]['URL_SLUG']
      if(resp['status']['code'] === "SUCCESS") {
        this.spinner.hide()
      } else {
       this.spinner.hide()
      }
    })
  }

  onLoadProductList() {
    this.spinner.show()
    this.authService.getProductList().subscribe(resp=>{
      this.productList = resp['primary']
      if(resp['status']['code'] === "SUCCESS") {
        this.spinner.hide()
      } else {
       this.spinner.hide()
      }
    })
  }


  onSubmit(form: NgForm, e) {
    e.preventDefault();
    console.log("formmmmmmm", this.model)
    if (form.invalid) {
      return
    }

    this.spinner.show();
    console.log("this.model",this.model)
    if(this.submitToggle) {
    this.authService.createProduct(this.model).subscribe(resp=>{
       if(resp['status']['code'] === "SUCCESS") {
         this.spinner.hide()
        Swal.fire(
          'Successful!',
          'Created',
        )
        $('#staticBackdrop').modal('hide');
        this.onLoadProductList()
        form.resetForm()
       } else {
        this.spinner.hide()
        Swal.fire({
          icon: 'error',
          text: 'Something went wrong!',
        })
       }
       
     })
    } else {
      this.authService.updateProduct(this.model).subscribe(resp=>{
        if(resp['status']['code'] === "SUCCESS") {
          this.spinner.hide()
         Swal.fire(
           'Successful!',
           'Created',
         )
         $('#staticBackdrop').modal('hide');
         this.onLoadProductList()

        } else {
         this.spinner.hide()
         Swal.fire({
           icon: 'error',
           text: 'Something went wrong!',
         })
        }
        
      })

    }


  }

  onClose() {
    this.submitToggle = true
    let formmm = document.getElementById("myForm") as HTMLFormElement;
    console.log("formmmmm",formmm)
    formmm.reset();
  }
}


