import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
activated: any
  imageToggleRealcollab: boolean 
  imageToggleRealreco: boolean 
  imageToggleRealtrace: boolean 
  imageToggleRealwarrnty: boolean
  constructor() { }
  
  ngOnInit() {
    this.activated = window.location.pathname.substring(1)
    // this.activated = window.location.hash.split('/')[1]

    
    console.log("activated",this.activated)

  }

}
