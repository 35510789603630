import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: any;
  userdetails: any;

  public loginState = new Subject();
  baseUrl= "https://api.marketplace.realware.tech/webhook/"

  constructor(private http: HttpClient) {
  }

  setToken(token: any) {
    console.log("tokennn", token)
    this.token = token;
    // this.loginStateChanged();
    sessionStorage.setItem("Token", token);
  }

  getToken() {
    return this.token
  }

  getTokenStorage() {
    return sessionStorage.getItem("Token")
  }

  getLoggedIn() {
    console.log("storageee", sessionStorage.getItem("Token"))
    if (sessionStorage.getItem("Token") !== undefined && sessionStorage.getItem("Token") !== null) {
      return true
    }
    else {
      return false
    }
  }

  // getLoggedUserDetails() {
  //    return JSON.parse(sessionStorage.getItem("userdetails"))
  // }

  // setLoggedUserDetails(userdetails:any) {
  //   this.userdetails =  userdetails
  //   sessionStorage.setItem("userdetails", JSON.stringify(userdetails));

  // }



  // getResovleApi(msMarketplaceToken: any) {
  //   console.log("getResovleApimsMarketplaceToken", msMarketplaceToken)
  //   return new Promise((resolve, reject) => {
  //     this.http.get('https://api.marketplace.realware.tech/webhook/resolve', {
  //       params: {
  //         // "x-ms-correlationid": "124d9436-4e6d-4411-9b2a-6d582906c171",
  //         // "x-ms-requestid": "124d9436-4e6d-4411-9b2a-6d582906c171",
  //         "x-ms-marketplace-token": msMarketplaceToken,
  //         "authorization": this.getTokenStorage()
  //       }
  //     }).subscribe(resp => {
  //       console.log("ResolveAPI", resp)
  //       resolve(resp)
  //     })
  //   })

  // }

  createProduct(obj: any) {
    return  this.http.post(this.baseUrl+'createAppDetails',obj)
  }

  updateProduct(obj: any) {
    return  this.http.post(this.baseUrl+'updateAppDetails',obj)
  }

  getProductListByURLSlug(urlSlug: any) {
    return  this.http.post(this.baseUrl+'getAppDetailsByUrlSlug',{URL_SLUG: urlSlug})
  }

  getProductList() {
    return  this.http.post(this.baseUrl+'getAppDetailsList',undefined)
  }

  getAuditLogsList() {
    return  this.http.post(this.baseUrl+'getAuditLogsList',undefined)

  }

  getSubscriptionsByClientId(client) {
    return  this.http.post(this.baseUrl+'getSubscriptionsByClientId',{CLIENT_ID: client})

  }



  loginStateChanged() {
    this.loginState.next();
  }
}
