import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './components/landing-page/products/products.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { SubscriptionDetailsComponent } from './components/subscription-details/subscription-details.component';


const routes: Routes = [
{path: '', component: ProductsComponent},
{path: 'audit-log', component: AuditLogComponent},
{path: 'subscription-details', component: SubscriptionDetailsComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
