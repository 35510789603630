import { Component, OnInit } from '@angular/core';
import { TreeTableData, TreeTableHeaderObject, TreeTableRow } from 'angular-tree-table';
import { AuthService } from '../service/auth.service';
import {  ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss']
})
export class SubscriptionDetailsComponent implements OnInit {
  tableConfig = {
    context: this,
    rowClickablesContext: this,
    rowClickables: {},
    columnFilters: {},
    columnVisibilityDropDown: false,
    showPageLengthDropdown: true,
    showExpandAllArrows: true,
    commonSearch: false,
    excelExportButton: false,
    visibleColumnFiltersVisibility: false,
    canChangeVisbilityOnRuntime: false,
    fullClassName: 'table table-striped table-bordered',
  };
  tableData: TreeTableData = new TreeTableData(); 
  tableHeaders: TreeTableHeaderObject[] = []; 
  clientIds
  appName
  constructor(private authService: AuthService, private router: ActivatedRoute) { }

  ngOnInit() {
    console.log("rourterr", this.router)
    this.clientIds = this.router.snapshot.queryParams['cliendId']
    this.appName = this.router.snapshot.queryParams['appName']
    this.auditLogData()
  }

  auditLogHeaders() {
    this.tableHeaders.splice(0, this.tableHeaders.length);
    this.tableHeaders.push(new TreeTableHeaderObject('Subscriton ID', 'SUBSCRIPTION_ID', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Subscriber Name', 'NAME', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Subscriber Email', 'EMAIL_ID', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Plan ID', 'PLAN_ID', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Offer Id', 'OFFER_ID', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Status', 'SUBSCRIPTION_STATUS', null, true));
    this.tableData.headers = this.tableHeaders;
  }



  auditLogData() {
    const data = [];
    this.authService.getSubscriptionsByClientId(this.router.snapshot.queryParams['cliendId']).subscribe(resp => {
      console.log("resppppp", resp)
      for (let i = 0; i < resp['primary'].length; i++) {
        const row = new TreeTableRow(i + '',resp['primary'][i] , false, null);
        data.push(row);
      }
    })

    this.tableData = new TreeTableData(this.tableConfig);
    this.auditLogHeaders();
    this.tableData.data = data;
  }

}
