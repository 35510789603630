import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopbarComponent } from './components/layout/topbar/topbar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AuthService } from './components/service/auth.service';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { ProductsComponent } from './components/landing-page/products/products.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { AngularTreeTableModule } from 'angular-tree-table';
import { SubscriptionDetailsComponent } from './components/subscription-details/subscription-details.component';
import { ExtraelePipe } from './extraele.pipe';

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    FooterComponent,
    ProductsComponent,
    AuditLogComponent,
    SubscriptionDetailsComponent,
    ExtraelePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxSpinnerModule,
    AngularTreeTableModule,
    HttpClientModule

    
  ],
 
  providers: [
    // {
    // provide: HTTP_INTERCEPTORS,
    //  useClass: Token,
    //  multi: true
    // },
    AuthService,NgxSpinnerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
