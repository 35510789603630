import { Component, OnInit } from '@angular/core';
import { TreeTableData, TreeTableHeaderObject, TreeTableRow } from 'angular-tree-table';
import { AuthService } from '../service/auth.service';
declare var $: any;

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
  tableConfig = {
    context: this,
    rowClickablesContext: this,
    rowClickables: {},
    columnFilters: {},
    columnVisibilityDropDown: false,
    showPageLengthDropdown: true,
    showExpandAllArrows: true,
    commonSearch: false,
    excelExportButton: false,
    visibleColumnFiltersVisibility: false,
    canChangeVisbilityOnRuntime: false,
    fullClassName: 'table table-striped table-bordered',
  };
  tableData: TreeTableData = new TreeTableData(); 
  tableHeaders: TreeTableHeaderObject[] = []; 
  responseData 
  requestData
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.auditLogData()
  }

  auditLogHeaders() {
    this.tableHeaders.splice(0, this.tableHeaders.length);
    this.tableHeaders.push(new TreeTableHeaderObject('URL', 'URL', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Request', 'ViewRequest', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Correlation ID', 'CORRELATION_ID', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Request ID	', 'REQUEST_ID', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Response Code', 'RESPONSE_CODE', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Response', 'ViewResponse', null, true));
    this.tableHeaders.push(new TreeTableHeaderObject('Invoked On', '$VD:INVOKED_ON', null, true));
    this.tableData.headers = this.tableHeaders;
  }



  auditLogData() {
    const data = [];
    this.authService.getAuditLogsList().subscribe(resp => {
      console.log("resppppp", resp)
       
      for (let i = 0; i < resp['primary'].length; i++) {
        resp['primary'][i]['ViewRequest'] = 'View'
        resp['primary'][i]['ViewResponse'] = 'View'

        const row = new TreeTableRow(i + '',resp['primary'][i] , false, null);
        row.clickablesContext = this;
        row.clickables = {ViewResponse: this.RESPONSE, ViewRequest: this.Request};
        data.push(row);
      }
    })

    this.tableData = new TreeTableData(this.tableConfig);
    this.auditLogHeaders();
    this.tableData.data = data;
  }

  RESPONSE(data) {
   console.log("response",data)
   $('#responseModal').modal('show');
   this.responseData = data['RESPONSE']
   
  }

  Request(data) {
  console.log("request",data)
  $('#requestModal').modal('show');
  this.requestData = data['REQUEST']
  }



}
