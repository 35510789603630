import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './components/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'azure';

  constructor(private http: HttpClient, private authService: AuthService) { }

  ngOnInit() {
    // http://localhost:4004/webhook
    // this.http.get('https://api.marketplace.realware.tech/webhook').subscribe(resp=>{
    //   this.authService.setToken(resp['tokeninfo']['access_token'])
    // })
  }

}
